import React, { useState } from "react";
import banner_img from "../assets/images/homeabn3.jpg";
import Typography from "@mui/material/Typography";
import { Snackbar, TextField } from "@mui/material";
import twiter_icon from "../assets/images/twitter_icon.png";
import fb_icon from "../assets/images/facebook_icon.png";
import insta_icon from "../assets/images/insta_icon.png";
// import phone_icon from "../images/phon_icon.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import youtube_icon from "../assets/images/yt_icon.png";
// import { AnimatedOnScroll } from "react-animated-css-onscroll";
import emailjs from "@emailjs/browser";
import LoadingButton from "@mui/lab/LoadingButton";

export default function HomeSlider() {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [formState, setFormState] = useState({
    name: "",
    gender: "",
    nameOfParent: "",
    location: "",
    grade: "",
    contactNo: "",
    email: "",
  });

  const [errors, setErrors] = useState("");

  const handleSubmit = async () => {
    // console.log("Submit===>>>>","gender",formState.gender == "")
    if (
      !formState.name ||
      !formState.gender ||
      !formState.nameOfParent ||
      !formState.location ||
      !formState.grade ||
      !formState.contactNo ||
      !formState.email
    ) {
      setErrors("Fill all the required fields");
      // alert("formState == ",JSON.stringify(formState.name))
      console.log("fill all the fields = ", formState);
    } else if (formState.contactNo.length < 10) {
      setErrors("Enter 10 digit Mobile Number");
      console.log(
        "Enter 10 digit mobile Number ====",
        formState.contactNo.length
      );
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formState.email)
    ) {
      setErrors("Invaliad Email Address");
      console.log("Invaild email no ====", formState.email);
    } else {
      setErrors("");
      handleClick();
      setLoader(true);

      console.log("successfully compeleted ====", formState);
      emailjs
        .sendForm(
          "service_uyk8dy7",
          "template_h1l3h2i",
          "#fis-form",
          "ctjg1i7xygc6_J6w_"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response, response.text);
            setLoader(false);
          },
          function (err) {
            console.log("FAILED...", err);
          }
        );
    }
  };

  const action = (
    <>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div data-aos="fade-down">
      <div id="banner_id" className="bann_img_cls">
        <img className="banner_img" src={banner_img} alt="some new containt" />
        <div className="social_media_container">
          <div className="social_img_div">
            <a
              href="https://twitter.com/thane_fis"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twiter_icon} alt="some new containt" />
            </a>
          </div>
          <div className="social_img_div">
            <a
              href=" https://www.facebook.com/finlandinternationalschoolthane"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb_icon} alt="some new containt" />
            </a>
          </div>
          <div className="social_img_div">
            <a
              href="https://www.instagram.com/thane_fis/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta_icon} alt="some new containt" />
            </a>
          </div>
          <div className="social_img_div">
            <a
              href="https://www.youtube.com/@thane_fis"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube_icon} alt="some new containt" />
            </a>
          </div>
          {/* <div className='social_img_div'>
                    <a href=''>
                    <img src={phone_icon} alt='some new containt'  />
                    </a>
                </div> */}
        </div>

        <Typography className="text_ban_cls" variant="h5">
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
            // data-aos-offset="300"
            // data-aos-easing="ease-in-sine"
            // animationIn="fadeInRight"
            // animationInDelay={50}
            // animationInDuration={1000}
            // className='form_div'
          >
            World's best education system,<br></br> now in <b>South Mumbai.</b>
          </div>
        </Typography>

        <div
          data-aos="fade-left"
          data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-sine"
          // animationIn="fadeInLeft"
          // animationInDelay={50}
          // animationInDuration={1000}
          className="form_div"
        >
          {/* <div  data-aos="fade-left"
    data-aos-delay="50"
    data-aos-duration="1000" className='form_div'> */}
          <form id="fis-form">
            <div>
              <Typography variant="h3" className="form_ti">
                Enquire now
              </Typography>

              <div className="form_text_con">
                <Typography variant="h6">Name of child</Typography>
                <TextField
                  // error
                  // helperText={"Enter A name for this child"}
                  name="to_name"
                  className="text_field_form"
                  id="standard-helperText"
                  variant="standard"
                  value={formState?.name}
                  onChange={(e) => {
                    setFormState({ ...formState, name: e.target.value });
                  }}
                />
              </div>

              <div className="form_text_con">
                <Typography variant="h6">Gender</Typography>
                <TextField
                  name="gender"
                  className="text_field_form"
                  id="standard-helperText"
                  variant="standard"
                  value={formState?.gender}
                  onChange={(e) => {
                    setFormState({ ...formState, gender: e.target.value });
                  }}
                />
              </div>

              <div className="form_text_con">
                <Typography variant="h6">Name of parent</Typography>
                <TextField
                  name="name-of-parent"
                  className="text_field_form"
                  id="standard-helperText"
                  variant="standard"
                  value={formState?.nameOfParent}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      nameOfParent: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="form_text_con">
                <Typography variant="h6">location</Typography>
                <TextField
                  name="location"
                  className="text_field_form"
                  id="standard-helperText"
                  variant="standard"
                  value={formState?.location}
                  onChange={(e) => {
                    setFormState({ ...formState, location: e.target.value });
                  }}
                />
              </div>

              <div className="form_text_con">
                <Typography variant="h6">grade</Typography>
                <TextField
                  name="grade"
                  className="text_field_form"
                  id="standard-helperText"
                  variant="standard"
                  value={formState?.grade}
                  onChange={(e) => {
                    setFormState({ ...formState, grade: e.target.value });
                  }}
                />
              </div>

              <div className="form_text_con">
                <Typography variant="h6">Contact no</Typography>
                <TextField
                  name="contact-no"
                  className="text_field_form"
                  id="standard-helperText"
                  variant="standard"
                  value={formState?.contactNo}
                  onChange={(e) => {
                    setFormState({ ...formState, contactNo: e.target.value });
                  }}
                />
              </div>

              <div className="form_text_con">
                <Typography variant="h6">email id</Typography>
                <TextField
                  name="user_email"
                  className="text_field_form"
                  id="standard-helperText"
                  variant="standard"
                  value={formState?.email}
                  onChange={(e) => {
                    setFormState({ ...formState, email: e.target.value });
                  }}
                />
              </div>
              <Typography className="error_msg" variant="h5">
                {errors}
              </Typography>
            </div>
            <LoadingButton
              loading={loader}
              className="form_btn"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Submit
            </LoadingButton>
            {/* <Button
                    className="form_btn"
                    variant="contained"
                    onClick={() => handleSubmit()}
                    >
                    Submit
                    </Button> */}
          </form>

          {/* </div> */}
        </div>
      </div>
      <div>
        {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Thank you for form submission"
          action={action}
        />
      </div>
    </div>
  );
}
