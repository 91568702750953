import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
// import Tooltip from '@mui/material/Tooltip';
import MenuItem from "@mui/material/MenuItem";
// import AdbIcon from '@mui/icons-material/Adb';
import logo from "../assets/images/fin_sobo-2.jpg";
// import phone_img from "../assets/images/phone-png.png";
import New_logo from "../assets/images/global_logo4.jpg";
import "../style/home.css";

const pages = [
  "Curriculum",
  "School Stages",
  "The Finnish Edge",
  "Cross-curricular learning",
  "About Us",
  "EduCluster Finland",
  "Our Global Faculty"
];
const page_id = [
  "Curriculum_id",
  "School_Stages_id",
  "Finnish_Edge_id",
  "Cross_curricular_id",
  "Goenka_id",
  "EduCluster_Finland_id",
  "Global_Faculty_id"
];

export default function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    <div className="header_class">
      <AppBar position="static" className="appBar">
        <div className="logo_container">
          <Box>
            <div className="logo_class">
              <a
                style={{
                  width: "inherit",
                  height: "inherit",
                  display: "block",
                }}
                href={window?.location?.origin}
              >
                <img src={logo} alt="some new containt" />
              </a>
            </div>
            <div className="phone_class">
              <div className="mennu_mobile">
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "none" },
                    padding: "0",
                  }}
                >
                  <IconButton
                    style={{ padding: "0" }}
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon
                      sx={{
                        fill: "black",
                        width: "50px",
                        height: "50px",
                        padding: "0",
                      }}
                    />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {pages.map((page, ind) => (
                      <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">
                          <a className="menu_nav_a" href={`#${page_id[ind]}`}>
                            {page}
                          </a>
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </div>
              <div className="new_logo">
                <a
                  style={{
                    width: "inherit",
                    height: "inherit",
                    display: "block",
                  }}
                  href={window?.location?.origin}
                >
                  <img src={New_logo} alt="some new containt" />
                </a>
              </div>
              {/* <div className="container_phon">
                <div className="phon_img">
                  <img src={phone_img} alt="some new containt" />
                </div>

                <Typography variant="h6">
                  <a href="tel:892-887-3775">Call Now</a>
                </Typography>
              </div> */}
            </div>
          </Box>
        </div>
        <Container
          sx={{
            display: { xs: "none", md: "block" },
            backgroundColor: "#DD4086",
            width: "100%",
            maxWidth: "100% !important",
            backgroundImage:
              "linear-gradient(90deg, rgb(139, 90, 164), rgb(166, 59, 146), rgb(139, 90, 164))",
          }}
          maxWidth="xl"
        >
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO 2
          </Typography> */}

            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO 1
          </Typography> */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                gap: "20px",
              }}
            >
              {pages.map((page, ind) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    fontSize: "18px",
                  }}
                >
                  <a className="menu_nav_a" href={`#${page_id[ind]}`}>
                    {page}
                  </a>
                </Button>
              ))}
            </Box>

            {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
