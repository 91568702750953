import React from 'react'
import Titledata from './Titledata'
import "../style/home.css"
import { Typography } from '@mui/material'

export default function Educluster_finland() {
  return (
    <div className='educluster_fin_main' id='EduCluster_Finland_id'>
        <Titledata title={"Educluster Finland"} data-aos="fade-down"/>
        <div className='educluster_text_container'>
            <Typography variant='h2' data-aos="zoom-in">
            FIS RACE COURSE IS AMONG THE FIRST SCHOOLS IN THE COUNTRY TO OFFER THE FINNISH WAY OF LEARNING.
            </Typography>

            <Typography variant='h2' data-aos="zoom-out">
            In partnership with EduCluster Finland (ECF)
            </Typography>
            
            <Typography variant='h2' data-aos="zoom-out">
            EduCluster Finland (ECF), University of Jyväskylä Group, co-develops innovative educational solutions with its three owners: the University of Jyväskylä, JAMK University of Applied Sciences, and Jyväskylä Educational Consortium Gradia. ECF has vast experience in reinventing the Finnish education concept to complement contexts and local needs worldwide. 
            </Typography>

            <Typography variant="h2" data-aos="zoom-out">
            EduCluster Finland has wide-ranging experience in co-creating inspirational, functional, and sustainable international learning communities with partners across the globe. Through these extensive partnerships, EduCluster Finland has pinpointed defining elements of Finland’s education concept that complement other education systems. 
            </Typography>

        </div>
    </div>
  )
}
