import React from 'react'
import "../style/home.css"
import Typography from "@mui/material/Typography";

export default function Titledata(props) {
  return (
    <div className='title_data'>
        <Typography variant='h2'>
        {props.title}
        </Typography>
        </div>
  )
}
