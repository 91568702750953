import React from 'react'
import Titledata from './Titledata'
import { Typography } from '@mui/material';
import Diversity_img from "../assets/images/start_img.png"
import Learning_img from "../assets/images/learning_img.png"
import Creative_img from "../assets/images/creative_img.png"
import Collab_img from "../assets/images/collaboration_img.png"
import Heart_img from "../assets/images/heart_img.png"

export default function TheFinishEdge() {
  return (
    <div className='Finnish_edge_main' id='Finnish_Edge_id'> 
        <Titledata title={"The Finnish Edge"} />

        <div className='finnish_five_div_cont' data-aos="zoom-in">
            <div className='finnish_all_content'>
                <div className='fin_conte_icon'>
                    <img src={Diversity_img} alt='some finnish edge content' />
                </div>
                <Typography variant='h2'>Respect For Diversity</Typography>
                <Typography variant='h6'>We are different yet we are the same.Let's embrace this.</Typography>
            </div>
            <div className='finnish_all_content'>
                <div className='fin_conte_icon'>
                    <img src={Learning_img} alt='some finnish edge content' />
                </div>
                <Typography variant='h2'>Responsibility for learning</Typography>
                <Typography variant='h6'>We are all learners for life. We trust the professionalism of our teachers, and we trust our students to be proactive learners.</Typography>
            </div>
            <div className='finnish_all_content'>
                <div className='fin_conte_icon'>
                    <img src={Creative_img} alt='some finnish edge content' />
                </div>
                <Typography variant='h2'>Courage to be creative</Typography>
                <Typography variant='h6'>To be a pioneer is to take educated risks. To be an innovator is to use your imagination.</Typography>
            </div>
            <div className='finnish_all_content'>
                <div className='fin_conte_icon'>
                    <img src={Collab_img} alt='some finnish edge content' />
                </div>
                <Typography variant='h2'>Collaboration for shared success</Typography>
                <Typography variant='h6'>Working together works.</Typography>
            </div>
            <div className='finnish_all_content'>
                <div className='fin_conte_icon'>
                    <img src={Heart_img} alt='some finnish edge content' />
                </div>
                <Typography variant='h2'>Global Citizenship</Typography>
                <Typography variant='h6'>We are citizens of the world, confidently navigating our own path to reshape the future.</Typography>
            </div>
        </div>
        <div className="btn_contact_container call_us_now_btn" data-aos="fade-up">
        {/* <Button variant="contained" className='butn_contact_us'>Call Us Now</Button> */}
        <a className='butn_lern_more' href='tel:849-606-0000'>
        Call Us Now
        </a>
        </div>
    </div>
  )
}
