// import { useEffect } from "react";
import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Home from "./pages/home";
function App() {
  return (
    <div className="app_finland_sobo">
      <Navbar />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
