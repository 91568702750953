import React from 'react'
import Slider from "react-slick";
import "../style/home.css"
import first_img from "../assets/images/positvi_baan-1.jpg"
import second_img from "../assets/images/world_bann-1.jpg"
import third_img from "../assets/images/recog_ban-1.jpg"

export default function FinlandBannerSlide() {

    var settings = {
        autoplay: true,
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow: false,
       
      };

  return (
    <div className='finland_slider_main' data-aos="zoom-down">
        <Slider {...settings}>
        <div className='finland_banner_div'>
            <img src={first_img} alt='some content slider'/>
        </div>
        <div className='finland_banner_div'>
            <img src={second_img} alt='some content slider'/>
        </div>
        <div className='finland_banner_div'>
            <img src={third_img} alt='some content slider'/>
        </div>
        </Slider>
    </div>
  )
}
