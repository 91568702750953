import React, { useState } from "react";
import Titledata from "./Titledata";
import { Typography } from "@mui/material";
import "../style/home.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Principal_img from "../assets/images/prin-2.jpg"
import Vice_Principal_img from "../assets/images/vice-2.jpg"


export default function OurGlobleFaculty() {
  // eslint-disable-next-line
  const [ourGlbData, setourGlbData] = useState([
    {
      readmore_trigger: false,
      glb_tit: "PRINCIPAL",
      constest_name: "Mr.Nikke Keskinen",
      imag_url: Principal_img,
      glb_p: [
        "I am Mr. Nikke Keskinen, the Principal of Finland International School (FIS) Race Course. We will open our doors in August 2023, and it’s already the third school in India to follow the Finnish National Core Curriculum.",
        "At FIS Race Course, learning life-skills together with the academics is at the heart of the innovative pedagogy our highly qualified teachers implement to assure your children can reach their own full potential. We value a positive atmosphere and collaboration across the school. Working together is part of our daily routines."
      ],
    },
    {
      readmore_trigger: false,
      glb_tit: "VICE PRINCIPAL",
      constest_name: "Ms. Tanja Kauppila",
      imag_url: Vice_Principal_img,
      glb_p: [
        "My name is Ms. Tanja Kauppila, Master of Education, from Finland. I am the Vice Principal and Class Teacher of Finland International School (FIS) Race Course. This is my first time in India, and I love the country and our school already. Before moving to Mumbai, I have worked as a Class Teacher in Qatar-Finland International School for three years, a year in UAE, and more than 15 years in Finland.",
        "I have a degree in Environmental Engineering as well, so my favourite subject is Math, especially building a strong foundation for it, through problem-solving and teaching the thinking skills. Sustainability is important to me. For example, I established a second-hand marketplace on Facebook almost 10 years ago in my hometown in Finland. With FIS Race Course students we will be using a lot of recycled materials for Crafts among other things.",
        "I am specialized in Pre-School and Primary Education, Special Pedagogics and Social Psychology. In our child-centred learning environment my goal is to make joyful learning happen in a positive and concrete way – Learning by doing. I warmly welcome you to FIS Race Course family and I am looking forward to collaborating with you!"
      ],
    },
  ]);

  const readmorefun = (ele, ind) => {
    const updatedGlobData = [...ourGlbData];

    // Update the object at the specified index with the new data
    updatedGlobData[ind] = {
      ...updatedGlobData[ind],
      readmore_trigger: !updatedGlobData[ind].readmore_trigger,
    };

    // Update the state with the new array
    setourGlbData(updatedGlobData);
  };

  return (
    <div className="OurGlobleFaculty_main" id="Global_Faculty_id">
      <Titledata title={"Our Global  Faculty"} data-aos="fade-down" />

      {ourGlbData?.map((gldData, ind) => (
        <>
          <div className="OurGlobleFaculty_img_text" data-aos="fade-right">
            <div className="globle_image_cont">
              <Typography variant="h2" className="main_name_tit">
                {gldData?.glb_tit}
              </Typography>
              <div className="pr_image_container">
                <img src={gldData?.imag_url} alt="some content" />
              </div>
              <Typography variant="h2" className="name_image_contest">
                {gldData?.constest_name}
              </Typography>
            </div>

            <div
              className={`globle_paragraph_cont`}
            >
              {/* {gldData?.glb_p > 1 ?"read more":"read less"}
          {gldData?.glb_p.length} */}
              <div  className={`read_con_glb ${
                gldData?.readmore_trigger ? "read_less_clas" : ""
              }`}>
                {gldData?.glb_p?.map((li_data, ind) => (
                  <>
                    {/* {li_data.length } */}
                    {/* {li_data.length > 350 ?"tr":"fals"} */}
                    {/* {ind > 0 ?"read more":"read less"} */}
                    <Typography variant="h2">{li_data}</Typography>
                  </>
                ))}
              </div>
              <div
                className={`read_div read_more_glb_faculty`}
                onClick={(ele, dd) => {
                  readmorefun(ele, ind);
                  // setCrossData({...crossdata,readmore_trigger:"true"})
                  // updateCrossData(number)
                }}
              >
                <Typography variant="h4">
                  {`${gldData?.readmore_trigger ? "Read less" : "Read more"}`}
                  <ChevronRightIcon className="arrow_svg" />
                </Typography>
              </div>
            </div>
          </div>
        </>
      ))}

      <div className="btn_contact_container" data-aos="fade-up">
        <a href="#banner_id" className="butn_contact_us">
          Contact Us
        </a>
      </div>
    </div>
  );
}
