import React from 'react'
import Titledata from './Titledata'
import "../style/home.css"
import { Typography } from '@mui/material'

export default function Curriculum() {
  return (
    <div className='' data-aos="fade-up" id='Curriculum_id'> 
        <Titledata title={"Curriculum"}/>
        <div className='curriculum_text'>
            <Typography variant='h5'>
            Finland International School Race Course follows Finnish education concept, which is internationally acknowledged for producing high-ranking student performance, while nurturing well-being for the whole learning community. IBDP examination will be provided to our students to secure their study path in India and internationally.
            </Typography>
        </div>
    </div>
  )
}
