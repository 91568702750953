import React from 'react'
import HomeSlider from '../Components/HomeSlider'
import Curriculum from '../Components/Curriculum'
import Schoolstages from '../Components/Schoolstages'
import GonekaGlbEduc from '../Components/Gonekaglbeduc'
import EduclusterFinland from '../Components/Educluster_finland'
import OurGlobleFaculty from '../Components/OurGlobleFaculty'
import TheFinishEdge from '../Components/TheFinishEdge'
import FinlandBannerSlide from '../Components/FinlandBannerSlide'
import CrossCurricularTheme from '../Components/CrossCurricularTheme'

export default function home() {
  return (
    <div>
        <HomeSlider/>
        <Curriculum/>
        <Schoolstages/>
        <TheFinishEdge/>
        <FinlandBannerSlide/>
        <CrossCurricularTheme/>
        <GonekaGlbEduc/>
        <EduclusterFinland/>
        <OurGlobleFaculty/>
    </div>
  )
}
