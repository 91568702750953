import React, { useState } from "react";
import Titledata from "./Titledata";
import { Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import versatile_img from "../assets/images/Stud_Image_1.jpg";
import own_learning_img from "../assets/images/S_72-1.jpg";
import Stud_family_img from "../assets/images/good-looking_img1.jpg";

export default function CrossCurricularTheme() {
  // const [readmore, setReadmore] = useState(false);
  // const [firstind, setfirstInd] = useState();
  // eslint-disable-next-line
  const [crossdata, setCrossData] = useState([
    {
      id:0,
      readmore_trigger: false,
      crosstitle: "Versatile working methods",
      crossImg: versatile_img,
      crossPgraph: [
        "Give the students a joy of learning and experiences of success,while supporting creative activities characteristic of different age groups.",
        "Develop social, learning, thinking, working, and problem-solving skills, and  foster active participation.",
      ],
    },
    {
      id:1,
      readmore_trigger: false,
      crosstitle: "Responsibility for their own learning",
      crossImg: own_learning_img,
      crossPgraph: [
        "While the teacher’s role as a facilitator is important, we trust and support students to ensure each one’s personal learning goals are met.",
        " Motivation is strengthened by working methods that support self-regulation and the feeling of being part of a group.",
      ],
    },
    {
      id:2,
      readmore_trigger: false,
      crosstitle: "Collaboration with the students’ families",
      crossImg: Stud_family_img,
      crossPgraph: [
        "There are several studies showing that the students coming from homes that work together with the school and show interest for education, perform much better socially and academically than their peers without this support.",
        "We work with you to bring out the best in your child.",
      ],
    },
  ]);

  const readmorefun = (ele, ind) => {
    console.log(
      "first read ---",
      ele.target,
      "index == ",
      ind
    );

    const updatedCrossData = [...crossdata];

    // Update the object at the specified index with the new data
    updatedCrossData[ind] = { ...updatedCrossData[ind],readmore_trigger: !updatedCrossData[ind].readmore_trigger };

    // Update the state with the new array
    setCrossData(updatedCrossData);

    // setReadmore(!readmore);
    // setfirstInd(ind);
    //  setCrossData({...crossdata, crossData[index].readmore: true})
  };
  
  return (
    <div id="Cross_curricular_id">
      <Titledata
        title={"Cross-curricular themes and learning"}
        data-aos="fade-down"
      />
      <Typography variant="h4" className="main_p_cross" data-aos="zoom-out">
        Students do not study only subjects, but themes and phenomena.{" "}
      </Typography>
      <div className="cross_curri_div_cont">
        {crossdata.map((data, number) => (
          <>
            <div className="cross_img_text_cont" data-aos="fade-up">
              <img src={data?.crossImg} alt="some cross curriculum content" />
              <div
                className={`cross_all_text_div ${
                  data?.readmore_trigger ? "cross_acti_read_more" : ""
                }`}
              >
                <Typography variant="h2">
                  {data?.crosstitle }
                </Typography>
                <div
                  className={`Read_more_div read_more_height`}
                  data-readmore={`${data?.readmore_trigger}`}
                >
                  {data.crossPgraph.map((p_data) => (
                    <>
                      <Typography variant="h6">{p_data}</Typography>
                    </>
                  ))}
                </div>
                <div
                  className={`read_div `}
                  onClick={(ele, ind) => {
                    readmorefun(ele, number);
                    // setCrossData({...crossdata,readmore_trigger:"true"})
                    // updateCrossData(number)
                  }}
                >
                  <Typography variant="h4">
                    {`${data?.readmore_trigger?"Read less":"Read more"}`}
                    <ChevronRightIcon className="arrow_svg" />
                  </Typography>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
