import React from "react";
import Titledata from "./Titledata";
import { Typography } from "@mui/material";
import "../style/home.css";

export default function Goneka_glb_educ() {
  return (
    <div className="Goneka_glb_educ_main" id="Goenka_id">
      <Titledata title="GOENKA GLOBAL EDUCATION" data-aos="fade-down" />
      <Typography variant="h3" className="p_global_edu" data-aos="zoom-in">
        Goenka Global Education is a forerunning educational enterprise in
        India, and committed to offering holistic and transformative education
        through innovation and experiential learning environments.
      </Typography>

      <Typography variant="h3" className="p_global_edu" data-aos="zoom-in">
        In the search for high-quality, high-impact school systems, Goenka
        Global Education selected Finland as the model to follow. Finland’s
        education concept is internationally acclaimed for its impressive track
        record in producing top-level student performance while nurturing
        community-wide wellbeing. The resounding success of Finnish education is
        evidenced by superb results in global education rankings.
      </Typography>

      <Typography variant="h3" className="p_global_edu" data-aos="zoom-in">
        At FIS Race Course, created by Goenka Global Education, the curriculum
        is centred around empowering students with the right kind of thinking,
        abilities and skill sets which ensures that they are successful to
        compete in a fiercely competitive global environment, but also being
        seen as individual children and youth, as who they are now.
      </Typography>
    </div>
  );
}
